// compiled from carousel.ts
document
    .querySelectorAll("body:not([data-edit-mode]) .carousel:not([data-activated]) .carousel-slides")
    .forEach((carousel, indexCarousel) => {
        const containerID = "carousel-" + indexCarousel;
        if (carousel instanceof HTMLElement) {
            carousel.dataset.id = containerID;
        }
        const slides = Array.from(carousel.querySelectorAll(".contentcarouselslide")).filter(
            (s) => !s.innerHTML.includes("data-has-expired")
        );
        const controls = document.createElement("div");
        if (slides.length) controls.classList.add("carousel-controls");
        carousel.parentElement?.appendChild(controls);
        const navButtons = [];
        const controlClickHandler = (event) => {
            event.preventDefault();
            if (slides.length < 2) return;
            const target = event.target;
            let slideToOutro = null;
            slides.forEach((slide) => {
                if (slide.classList.contains("active")) {
                    slideToOutro = slide;
                }
                slide.classList.remove("active", "outro");
            });
            slideToOutro?.classList.add("outro");
            slideToOutro?.setAttribute("inert", "");
            const currentTarget = document.querySelector(target.getAttribute("href"));
            currentTarget?.classList.add("active");
            currentTarget?.removeAttribute("inert");
            controls.querySelectorAll("a").forEach((control) => control.classList.remove("active"));
            const curIndex = parseInt(currentTarget.dataset.id, 10);
            const nextIndex = curIndex >= slides.length - 1 ? 0 : curIndex + 1;
            nextButton.href = `[data-id='${containerID}'] [data-id='${nextIndex}']`;
            navButtons[curIndex]?.classList.add("active");
        };
        if (slides.length === 1 && slides[0]) {
            slides[0].classList.add("active");
        }
        if (slides.length > 1) {
            slides.forEach((slide, indexSlide) => {
                if (indexSlide > 0) {
                    slide.setAttribute("inert", "");
                }
                slide.dataset.id = indexSlide.toString();
                const clickTarget = Object.assign(document.createElement("a"), {
                    href: `[data-id='${containerID}'] [data-id='${indexSlide}']`,
                    innerText: indexSlide,
                    tabIndex: -1,
                    onclick: (e) => controlClickHandler(e),
                });
                controls.appendChild(clickTarget);
                navButtons.push(clickTarget);
                if (indexSlide === 0) {
                    slide.classList.add("active");
                    clickTarget.classList.add("active");
                }
            });
        }
        const nextText = carousel.dataset.nextText ?? "Next";
        const nextButton = Object.assign(document.createElement("a"), {
            className: "next-button",
            href: `[data-id='${containerID}'] [data-id='1']`,
            innerText: " ",
            ariaLabel: nextText,
            onclick: (e) => controlClickHandler(e),
        });
        if (slides.length > 1) {
            carousel.appendChild(nextButton);
        }
        const gesture = {
            x: [],
            y: [],
        };
        carousel.addEventListener(
            "touchstart",
            (e) => {
                for (let i = 0; i < e.touches.length; i++) {
                    gesture.x.push(e.touches[i].clientX);
                    gesture.y.push(e.touches[i].clientY);
                }
            },
            globals.passiveSupported
        );
        carousel.addEventListener(
            "touchmove",
            (e) => {
                for (let i = 0; i < e.touches.length; i++) {
                    gesture.x.push(e.touches[i].clientX);
                    gesture.y.push(e.touches[i].clientY);
                }
            },
            globals.passiveSupported
        );
        carousel.addEventListener(
            "touchend",
            () => {
                const tolerance = 100;
                let xTravel = (gesture.x[gesture.x.length - 1] ?? 0) - (gesture.x[0] ?? 0),
                    yTravel = (gesture.y[gesture.y.length - 1] ?? 0) - (gesture.y[0] ?? 0);
                if (yTravel < tolerance && yTravel > -tolerance && xTravel < -tolerance) {
                    nextButton.click();
                }
                gesture.x = [];
                gesture.y = [];
                xTravel = yTravel = 0;
            },
            globals.passiveSupported
        );
        carousel.closest(".carousel").dataset.activated = "true";
    });
