// compiled from countrySelector.ts
class CountrySelector {
    constructor() {
        this.languagePicker = document.querySelector(".language-picker");
        const url = this.languagePicker?.dataset?.countriesUrl;
        if (typeof url === "string" && url) {
            this.url = url;
        } else {
            console.warn("Language json data not found");
            this.url = "/global/en/country-language-selector/_jcr_content.languageSelector.json";
        }
        this.popup = document.getElementById("langOverlay");
        this.countrySelect = document.getElementById("countries");
        this.languageSelector = document.getElementById("languages");
        this.toggleButton = document.querySelector('button[aria-controls="langOverlay"]');
        this.closeButton = document.querySelector("#langOverlay .close");
        this.submitButton = document.getElementById("changeCountry");
        this.boundOutsideClickListener = this.listenToOutsideClick.bind(this);
        this.currentCountry = null;
        this.currentLanguage = null;
        this.isFetched = false;
        this.countries = [];
        this.defaults = [
            {
                name: "Global",
                languages: [{ name: "English", url: "/global/en.html", lang: "en-global" }],
            },
        ];
        if (!this.languagePicker || !this.toggleButton) {
            console.warn("Required country selector not found. CountrySelector initialization aborted.");
            return;
        }
        this.addEvents();
    }
    addEvents() {
        this.toggleButton?.addEventListener("click", () => this.toggleState("toggle"));
        this.closeButton?.addEventListener("click", () => this.toggleState("close"));
        globals.closePopup.language = () => this.toggleState("close");
        this.countrySelect?.addEventListener("change", (e) => {
            const target = e.target;
            const country = this.countries.find((country) => country.name === target.value);
            if (country) {
                this.renderLanguages(country.languages);
            }
            this.userSelectedSomething();
        });
        this.languageSelector?.addEventListener("change", () => {
            this.userSelectedSomething();
        });
        this.submitButton?.addEventListener("click", () => {
            this.submitClick();
        });
    }
    listenToOutsideClick(e) {
        const target = e.target;
        const outside = !this.languagePicker.contains(target);
        if (outside) {
            this.toggleState("close");
            document.removeEventListener("click", this.boundOutsideClickListener);
        }
    }
    isValidReceivedData(data) {
        if (typeof data !== "object" || data === null) return false;
        const receivedData = data;
        if (!Array.isArray(receivedData.countries) || receivedData.countries.length === 0) return false;
        return receivedData.countries.every((item) => {
            if (typeof item !== "object" || item === null) return false;
            const c = item;
            return (
                typeof c.name === "string" &&
                Array.isArray(c.languages) &&
                c.languages.every((lang) => {
                    if (typeof lang !== "object" || lang === null) return false;
                    const l = lang;
                    return typeof l.name === "string" && typeof l.url === "string";
                })
            );
        });
    }
    fetchCountries() {
        globals
            .customFetch(this.url)
            .then((data) => {
                this.countries = this.isValidReceivedData(data) ? data.countries : this.defaults;
            })
            .catch((error) => {
                console.error("Error fetching countries:", error);
                this.countries = this.defaults;
            })
            .finally(() => {
                const currentSite = `/${globals.country}/${globals.langCode}.html`;
                const { country, language } = this.searchForCountry(currentSite);
                this.currentCountry = country;
                this.currentLanguage = language;
                this.renderCountries();
            });
    }
    searchForCountry(langUrl) {
        let curCountry;
        let curLanguage;
        for (const country of this.countries) {
            const language = country.languages.find((lang) => lang.url.includes(langUrl));
            if (language) {
                curCountry = country;
                curLanguage = language;
                break;
            }
        }
        if (curCountry && curLanguage) {
            return { country: curCountry, language: curLanguage };
        }
        console.error("Country and/or Language not found");
        return { country: this.defaults[0], language: this.defaults[0]?.languages[0] };
    }
    renderCountries() {
        const cs = this.countrySelect;
        if (!cs || !(cs instanceof HTMLSelectElement)) {
            return;
        }
        cs.innerHTML = "";
        this.countries.forEach((country) => {
            const option = document.createElement("option");
            option.textContent = country.name;
            cs.appendChild(option);
            if (this.currentCountry?.name === country.name) {
                option.selected = true;
                this.renderLanguages(country.languages);
            }
        });
    }
    renderLanguages(langs) {
        const ls = this.languageSelector;
        if (!(ls instanceof HTMLSelectElement)) return;
        ls.innerHTML = "";
        langs.forEach((language) => {
            const option = document.createElement("option");
            option.textContent = language.name;
            option.value = language.url;
            ls.appendChild(option);
            if (this.currentLanguage?.name === language?.name) {
                option.selected = true;
            }
        });
    }
    userSelectedSomething() {
        const cs = this.countrySelect;
        const ls = this.languageSelector;
        if (!(cs instanceof HTMLSelectElement && ls instanceof HTMLSelectElement)) return;
        const selectedCountry = cs.options[cs.selectedIndex]?.textContent;
        const selectedLanguage = ls.options[ls.selectedIndex]?.textContent;
        this.submitButton?.removeAttribute("disabled");
        this.submitButton?.setAttribute("aria-disabled", "false");
        const findCountry = this.countries.find((country) => country.name === selectedCountry);
        if (findCountry) this.currentCountry = findCountry;
        const findLanguage = this.currentCountry?.languages.find((lang) => lang.name === selectedLanguage);
        if (findLanguage) this.currentLanguage = findLanguage;
    }
    toggleState(state = "close") {
        if (!this.isFetched) {
            this.fetchCountries();
            this.isFetched = true;
        }
        if (state === "toggle") state = this.toggleButton?.getAttribute("aria-expanded") === "true" ? "close" : "open";
        this.toggleButton?.setAttribute("aria-expanded", state === "open" ? "true" : "false");
        this.closeButton?.setAttribute("aria-expanded", state === "open" ? "true" : "false");
        this.popup?.setAttribute("aria-hidden", state === "open" ? "false" : "true");
        if (state === "open") {
            globals.closePopup.partnerCentral();
            globals.closePopup.search();
            this.countrySelect?.focus();
            document.addEventListener("click", this.boundOutsideClickListener);
        } else {
            document.removeEventListener("click", this.boundOutsideClickListener);
        }
    }
    submitClick() {
        if (this.languageSelector instanceof HTMLSelectElement) {
            if (this.languageSelector.value.startsWith("http")) {
                window.location.href = this.languageSelector.value;
            } else {
                window.location.pathname = this.languageSelector.value;
            }
        }
    }
}
new CountrySelector();
