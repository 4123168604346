// The language selector is an optional component that may be added into a page
// for instance onto the global page, which allows the user to select a country

class LanguageSelector {
    constructor() {
        this.el = document.getElementById("language_selector_v2");
        if (!this.el || !this.el.dataset.countries) {
            return;
        }
        this.dom = {
            countryInput: this.el.querySelector('select[name="countries"]'),
            languagesInput: this.el.querySelector('select[name="languages"]'),
            languageSelectorBTN: this.el.querySelector("button"),
        };

        if (!this.dom.countryInput || !this.dom.languagesInput || !this.dom.languageSelectorBTN) {
            return;
        }

        //set up defaults
        const countryJSON = JSON.parse(this.el.dataset.countries);
        this.dom.countries = countryJSON.countries ? countryJSON.countries : [];
        //add listeners
        this.dom.countryInput.addEventListener("change", this.countryInputChange.bind(this));
        this.dom.languageSelectorBTN.addEventListener("click", this.selectLanguage.bind(this));

        this.populateCountrySelect();
        this.populateLanguageSelect();
        this.dom.selectedUrl = this.dom.languagesInput.value;
    }

    populateCountrySelect() {
        const countriesElements = this.dom.countries
            .map((country, index) => {
                return `<option value="${index}">${country.name}</option>`;
            })
            .join("");

        this.dom.countryInput.innerHTML = countriesElements;
    }

    countryInputChange(event) {
        this.populateLanguageSelect(parseInt(event.target.value));
    }

    populateLanguageSelect(countryIndex = 0) {
        if (!this.dom.countries.length) {
            //the countries haven't pulled through, so cancel, and hide the components except for button for all countries as it will not work
            this.dom.countryInput.style.display = "none";
            this.dom.languagesInput.parentElement.style.display = "none";
            this.dom.languageSelectorBTN.style.display = "none";
            return;
        }
        const languages = this.dom.countries[countryIndex].languages;
        const languageElements = languages
            .map((language) => {
                return `<option value="${language.url}">${language.name}</option>`;
            })
            .join("");

        this.dom.languagesInput.innerHTML = languageElements;
    }

    selectLanguage() {
        const url = this.dom.languagesInput.value;
        const win = window.open(url, "_blank");

        if (win) {
            win.focus();
        } else {
            console.error("Please allow popups for this website");
        }
    }
}
//only process once the dom is ready, to ensure the language component is found
document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementById("language_selector_v2")) {
        new LanguageSelector();
    }
});
