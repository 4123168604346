// compiled from video.ts
class YouTubeVideo {
    constructor(element) {
        this.playVideo = () => {
            if (!this.waiting) {
                this.dialog.showModal();
                if (!this.player) {
                    this.waiting = true;
                    const options = Object.assign(this.videoInfo, {
                        events: {
                            onReady: () => {
                                this.waiting = false;
                                if (this.player) this.player.playVideo();
                            },
                        },
                    });
                    this.player = new YT.Player(this.ytIframe.id, options);
                } else {
                    this.player.playVideo();
                }
            }
        };
        this.el = element;
        this.el.dataset.activated = "true";
        this.player = undefined;
        this.waiting = false;
        this.loadYouTubeScript().then(() => {
            this.el.addEventListener("click", this.playVideo.bind(this));
        });
        this.ytIframe = document.createElement("div");
        this.ytIframe.id = `ytplayer-${Math.random().toString(36).substring(7)}`;
        const closeFunction = () => {
            if (this.waiting) return;
            if (this.player) this.player.stopVideo();
        };
        this.dialog = globals.createNewModal(this.ytIframe, closeFunction);
        this.videoInfo = this.getVars(element.dataset.videoInfo);
        this.videoVars = this.getVars(element.dataset.videoVars);
        this.videoVars.hl = globals.langCodeFull;
    }
    getVars(input) {
        const result = {};
        if (!input || typeof input !== "string") return result;
        input.split(" ").forEach((pair) => {
            const [key, value] = pair.split(":");
            if (key && value) {
                result[key] = value;
            }
        });
        return result;
    }
    loadYouTubeScript() {
        return globals
            .dynamicScriptLoad("https://www.youtube.com/iframe_api", "defer")
            .catch((error) => console.error("Failed to load YouTube Script", error));
    }
}
document.querySelectorAll(".component-video:not([data-activated])").forEach((vid) => {
    new YouTubeVideo(vid);
});
