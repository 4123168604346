// compiled from header.ts
const burgerMenu = document.querySelector(".mobile-burger-menu");
const primaryNav = document.querySelector(".header-lower-navigation");
const primaryNavLinks = document.querySelectorAll(".primary-nav-link");
const menuOverlay = document.querySelector(".menu-open-overlay");
burgerMenu?.addEventListener("click", () => {
    document.body.classList.toggle("menu-open");
    primaryNavLinks.forEach((el) => {
        el.setAttribute("aria-expanded", "false");
    });
});
function closeAllMenus() {
    document.body.classList.remove("menu-open");
    primaryNavLinks.forEach((el) => {
        el.setAttribute("aria-expanded", "false");
    });
}
primaryNav?.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
        closeAllMenus();
    }
});
primaryNav?.addEventListener("click", (event) => {
    const target = event.target;
    const navButton = target?.closest(".primary-nav-link");
    if (!navButton || !target) return;
    globals.closePopup.closeAll();
    if (navButton.getAttribute("aria-expanded") === "true") {
        navButton.setAttribute("aria-expanded", "false");
        if (!(burgerMenu?.clientHeight > 0)) document.body.classList.remove("menu-open");
    } else {
        closeAllMenus();
        navButton.setAttribute("aria-expanded", "true");
        document.body.classList.add("menu-open");
    }
});
primaryNav?.addEventListener("keydown", (event) => {
    const target = event.target;
    if (!target || !Array.from(primaryNavLinks).includes(target)) return;
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        const currentIndex = Array.from(primaryNavLinks).findIndex((el) => el === target);
        const lastIndex = primaryNavLinks.length - 1;
        if (event.key === "ArrowLeft") {
            const previousIndex = Math.max(0, currentIndex - 1);
            primaryNavLinks[previousIndex]?.focus();
        } else if (event.key === "ArrowRight") {
            const nextIndex = Math.min(lastIndex, currentIndex + 1);
            primaryNavLinks[nextIndex]?.focus();
        }
    }
});
menuOverlay?.addEventListener("click", closeAllMenus);
