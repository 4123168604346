// compiled from blog-search-component.ts
class blogSearchResults {
    constructor() {
        this.listing = document.querySelector(".blog-search-module__listing");
        const itemTemplate = document.getElementById("blog-search-module__item-template");
        this.itemTemplate = itemTemplate.content;
        this.throttle = false;
        this.button = document.getElementById("load-more-articles-btn");
        this.searchInput = document.querySelector("#news-search");
        this.searchFetchUrl = this.button?.dataset.url ?? "";
        this.currentPage = parseInt(this.button?.dataset.currentPage ?? "1");
        const loadMoreObserver = new IntersectionObserver(
            (entries, _) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.loadMoreArticles();
                    }
                });
            },
            { threshold: 0.2 }
        );
        if (this.button) {
            loadMoreObserver.observe(this.button);
            this.button.addEventListener("click", this.loadMoreArticles, globals.passiveSupported);
        }
    }
    loadMoreArticles() {
        if (this.throttle) return;
        this.throttle = true;
        setTimeout(() => {
            this.throttle = false;
        }, 3000);
        this.button?.setAttribute("loading", "true");
        const data = new URLSearchParams({
            text: this.searchInput?.value ?? "",
            currentpage: (this.currentPage + 1).toString(),
            pagesize: this.button.dataset.pageSize ?? "1",
        }).toString();
        this.postData(this.searchFetchUrl, data).then((r) => this.onLoad(r));
    }
    postData(url, data) {
        return globals.customFetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: data,
        });
    }
    populateTemplate(template, data) {
        if (data.isFeatured) template.querySelector("article").setAttribute("data-featured", "");
        template.querySelector("a").href = data.url;
        template.querySelector("img").src = data.imageUrl;
        if (data.authorName === "") {
            template.querySelector(".author").remove();
        } else {
            template.querySelector(".author").textContent = data.authorName;
        }
        template.querySelector(".tag").textContent = data.primaryTagTitle;
        template.querySelector(".date").textContent = data.timeAgo;
        template.querySelector("h3 span").textContent = data.title;
        template.querySelector(".extract").textContent = data.description;
    }
    onLoad(response) {
        this.button?.removeAttribute("loading");
        if (!response) return;
        if (response.results) this.renderResults(response.results);
        if (response.pagination) {
            const currentPage = response.pagination.currentPage;
            if (currentPage === response.pagination.totalPages) {
                this.button?.setAttribute("disabled", "");
                return;
            } else {
                this.currentPage = currentPage;
                this.button?.setAttribute("data-current-page", this.currentPage.toString());
            }
        }
    }
    renderResults(results) {
        for (let i = 0; i < results.length; i++) {
            const item = this.itemTemplate.cloneNode(true);
            this.populateTemplate(item, results[i]);
            this.listing?.appendChild(item);
        }
    }
}
class blogNavigation {
    constructor() {
        this.overlay = document.getElementById("fullscreen-overlay");
        this.blogCategories = document.getElementById("news-summary-menu");
        this.blogCategoryButtons = this.blogCategories?.querySelectorAll("[aria-controls]");
        this.mobileCategoriesButton = document.getElementById("news-categories-button");
        if (!this.blogCategories || !this.blogCategoryButtons) return;
        globals.createAccessibleMenu(this.blogCategories, this.blogCategoryButtons, "block");
        this.blogCategories.addEventListener(
            "click",
            (event) => {
                const target = event.target;
                if (!target || !target.matches("[aria-controls]")) return;
                this.blogCategoryButtons?.forEach((b) => {
                    if (b === target) {
                        target.setAttribute("aria-expanded", target.getAttribute("aria-expanded") === "true" ? "false" : "true");
                        const popup = target.nextElementSibling;
                        if (popup instanceof HTMLElement) {
                            const buttonY = target.getBoundingClientRect().y;
                            const popupRect = popup.getBoundingClientRect();
                            const toTop = buttonY - popupRect.y;
                            const asPercentage = toTop / popupRect.height;
                            const yOffset = Math.ceil(asPercentage * 100);
                            popup.style.setProperty("--y-offset", yOffset + "%");
                            popup.style.setProperty("--circle-diameter", (Math.abs(50 - yOffset) / 50) * 34 + 105 + "%");
                            if (this.mobileCategoriesButton?.offsetParent !== null) {
                                setTimeout(() => {
                                    popup.querySelector("button")?.focus();
                                }, 255);
                            }
                        }
                    } else {
                        b.setAttribute("aria-expanded", "false");
                    }
                });
            },
            globals.passiveSupported
        );
        this.blogCategories?.addEventListener("keydown", (e) => {
            if (e.key === "Escape") {
                const cachedButton = this.blogCategories.querySelector("[aria-expanded='true']");
                this.blogCategoryButtons?.forEach((b) => {
                    b.setAttribute("aria-expanded", "false");
                });
                cachedButton?.focus();
            }
        });
        this.overlay?.addEventListener(
            "click",
            () => {
                this.blogCategoryButtons?.forEach((b) => {
                    b.setAttribute("aria-expanded", "false");
                });
            },
            globals.passiveSupported
        );
        document.querySelectorAll("#news-summary-menu .back").forEach((b) => {
            b.addEventListener(
                "click",
                (e) => {
                    const t = e.target;
                    t.closest("li")?.querySelector("[aria-expanded]")?.setAttribute("aria-expanded", "false");
                    t.closest(".news-pop-up")?.setAttribute("expanded", "false");
                },
                globals.passiveSupported
            );
        });
        this.mobileCategoriesButton?.addEventListener(
            "click",
            (e) => {
                const t = e.target;
                t.setAttribute("aria-expanded", t.getAttribute("aria-expanded") === "true" ? "false" : "true");
            },
            globals.passiveSupported
        );
    }
}
if (document.querySelector(".blog-search-module__listing")) {
    new blogSearchResults();
}
if (document.getElementById("news-summary-menu")) {
    new blogNavigation();
}
