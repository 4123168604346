// compiled from searchBox.ts
function searchBarHandler() {
    const MAX_SUGGESTIONS = 9;
    const searchBar = document.querySelector(".search-bar");
    if (!searchBar)
        return;
    const searchInput = searchBar.querySelector(".search-header-input");
    const searchFetchURL = searchBar.dataset?.contentSuggestions;
    const searchForm = document.getElementById("searchSuggestions");
    const searchLinkDestination = searchForm?.action;
    const searchResultsWrapper = searchBar.querySelector(".search-results-list");
    if (!searchInput || !searchFetchURL || !searchForm || !searchResultsWrapper)
        return;
    const suggestions = searchResultsWrapper.dataset?.suggestionsText || "Suggestions";
    const noResults = searchResultsWrapper.dataset?.noResults || "No results";
    const suggestEl = createStrongElement(suggestions);
    const noResultsEl = createStrongElement(noResults);
    function createStrongElement(text) {
        const el = document.createElement("strong");
        el.innerText = text;
        return el;
    }
    searchForm.addEventListener("submit", (event) => {
        event.preventDefault();
    });
    const toggleState = (state = "close") => {
        if (state === "toggle")
            state = searchForm.getAttribute("aria-expanded") === "true" ? "close" : "open";
        if (state === "open") {
            searchBar.setAttribute("aria-expanded", "true");
            searchForm.setAttribute("aria-hidden", "false");
            document.addEventListener("click", listenToClickOutsideSearch);
            setTimeout(() => {
                searchInput.focus();
            }, 0);
        }
        else {
            searchBar.setAttribute("aria-expanded", "false");
            searchForm.setAttribute("aria-hidden", "true");
            document.removeEventListener("click", listenToClickOutsideSearch);
        }
    };
    globals.closePopup.search = toggleState;
    const listenToClickOutsideSearch = (e) => {
        const target = e.target;
        if (!searchBar.contains(target)) {
            toggleState("close");
        }
    };
    searchBar.addEventListener("click", (e) => {
        if (!e.target.matches(".search-bar"))
            return;
        globals.closePopup?.language();
        globals.closePopup?.partnerCentral();
        toggleState("toggle");
    });
    function updateSearchResults(data) {
        searchResultsWrapper.innerHTML = "";
        if (data.suggestions?.length > 0) {
            searchResultsWrapper.appendChild(suggestEl);
            data.suggestions.slice(0, MAX_SUGGESTIONS).forEach((suggestion) => {
                const listItem = document.createElement("li");
                const link = document.createElement("a");
                link.href = `${searchLinkDestination}#query=${encodeURI(suggestion.value)}`;
                link.textContent = suggestion.value;
                listItem.appendChild(link);
                searchResultsWrapper.appendChild(listItem);
            });
        }
        else {
            searchResultsWrapper.appendChild(noResultsEl);
        }
    }
    function fetchSuggestions() {
        if (searchInput.value.trim() === "")
            return;
        globals
            .customFetch(`${searchFetchURL}.${encodeURI(searchInput.value)}.json`)
            .then(updateSearchResults)
            .catch((error) => console.error("Error fetching suggestions:", error));
    }
    const debouncedFetchSuggestions = globals.debounce(fetchSuggestions, 250);
    searchInput.addEventListener("input", debouncedFetchSuggestions);
}
searchBarHandler();
