// compiled from stickyContainer.ts
"use strict";
class StickyNav {
    constructor(el) {
        this.container = el;
        this.id = this.container.dataset.uuid || "";
        this.navLinks = this.container.querySelectorAll(".outline-link");
        this.navScroller = this.container.querySelector(`#sticky-${this.id} ul`);
        this.curAnchor = "";
        this.smooth = window.matchMedia("(prefers-reduced-motion: no-preference)").matches ? "smooth" : "instant";
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), this.getObserverOptions());
        this.init();
    }
    getObserverOptions() {
        return {
            root: null,
            rootMargin: `0px 0px ${window.innerWidth <= 600 ? "0px" : "50%"} 0px`,
            threshold: window.innerWidth <= 600 ? 0 : 0.2,
        };
    }
    handleIntersection(entries) {
        entries.forEach((entry) => {
            const el = entry.target;
            if (entry.isIntersecting) {
                el.dataset.visible = "true";
            } else {
                el.dataset.visible = "false";
            }
        });
        const firstVisible = this.container.querySelector(`section[data-visible="true"]`);
        this.curAnchor = firstVisible?.id || "";
        this.navLinks.forEach((el) => {
            if (el.getAttribute("href") === `#${this.curAnchor}`) {
                el.setAttribute("aria-current", "page");
                if (!this.isElementInViewport(el)) {
                    this.navScroller?.scroll({
                        left: el.getBoundingClientRect().x - 20,
                        behavior: this.smooth,
                    });
                }
            } else {
                el.removeAttribute("aria-current");
            }
        });
    }
    init() {
        this.container.querySelectorAll(`section[data-uuid="${this.id}"]`).forEach((el) => {
            this.observer.observe(el);
        });
    }
    isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}
document.querySelectorAll('[data-navigation-style="sticky"]').forEach((el) => {
    new StickyNav(el);
});
class TabPanels {
    constructor(el) {
        this.container = el;
        this.id = this.container.dataset.uuid || "";
        this.tabsContainer = this.container.querySelector(`#sticky-${this.id}`);
        this.tabBtns = this.container.querySelectorAll(`#sticky-${this.id} button`);
        this.panels = this.container.querySelectorAll(`[role="tabpanel"][data-uuid="${this.id}"]`);
        this.btnToPanelMap = new Map();
        this.openTabPanel = this.openTabPanel.bind(this);
        this.init();
    }
    init() {
        this.tabBtns.forEach((btn) => {
            const panelId = btn.getAttribute("aria-controls");
            const panel = panelId ? document.getElementById(panelId) : null;
            if (panel) {
                this.btnToPanelMap.set(btn, panel);
            }
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                const target = e.target;
                if (target) this.openTabPanel(target);
            });
        });
        this.panels.forEach((panel, i) => {
            if (i) panel.setAttribute("hidden", "true");
        });
        this.tabsContainer?.addEventListener("keydown", (e) => {
            switch (e.key) {
                case "ArrowLeft":
                    this.moveLeft();
                    break;
                case "ArrowRight":
                    this.moveRight();
                    break;
                case "Home":
                    e.preventDefault();
                    const firstTab = this.tabBtns[0];
                    if (firstTab) this.openTabPanel(firstTab);
                    break;
                case "End":
                    e.preventDefault();
                    const lastTab = this.tabBtns[this.tabBtns.length - 1];
                    if (lastTab) this.openTabPanel(lastTab);
                    break;
            }
        });
    }
    moveLeft() {
        const currentBtn = document.activeElement;
        const prevBtn = currentBtn?.parentElement?.previousElementSibling?.firstElementChild;
        if (prevBtn) {
            prevBtn.focus();
        }
    }
    moveRight() {
        const currentBtn = document.activeElement;
        const nextBtn = currentBtn?.parentElement?.nextElementSibling?.firstElementChild;
        if (nextBtn) {
            nextBtn.focus();
        }
    }
    openTabPanel(target) {
        const targetPanel = this.btnToPanelMap.get(target);
        this.tabBtns.forEach((btn) => {
            btn.setAttribute("aria-selected", "false");
            btn.setAttribute("tabindex", "-1");
        });
        this.panels.forEach((panel) => {
            panel.setAttribute("hidden", "true");
        });
        if (targetPanel) {
            targetPanel.removeAttribute("hidden");
            target.setAttribute("aria-selected", "true");
            target.setAttribute("tabindex", "0");
        }
    }
}
document.querySelectorAll('[data-navigation-style="tabs"],[data-navigation-style="gallery"]').forEach((el) => {
    new TabPanels(el);
});
