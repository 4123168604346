//––––––––––––––––––––––––––––––––––––––––
//  Dynamic counter component
//––––––––––––––––––––––––––––––––––––––––
document.querySelectorAll("body:not([data-edit-mode]) .dynamic-counter[data-value]").forEach((el) => {
    el.style.setProperty("--dc-num", el.dataset.value);
    if (el.dataset.from) {
        el.style.setProperty("--dc-from", el.dataset.from);
    }
    if (el.dataset.delay) {
        el.style.setProperty("--dc-delay", el.dataset.delay + "ms");
    }
    if (el.dataset.duration && el.dataset.duration !== "0") {
        el.style.setProperty("--dc-duration", el.dataset.duration + "ms");
    }

    globals.isVisibleObserver.observe(el);
});
