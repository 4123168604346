// compiled from vendors-menu.ts
const vendorList = document.querySelectorAll(".vendors-list-of-vendors a");
const vendorFilters = document.querySelector(".vendors-filters");
if (vendorList && vendorFilters) {
    const vl = document.createElement("div");
    vl.ariaHidden = "true";
    const alp = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",];
    const allAlp = alp.map((a) => {
        return { l: a, c: 0 };
    });
    vendorList.forEach((v) => {
        if (v.title) {
            const a = v.title.slice(0, 1).toUpperCase();
            v.id = `v${a}`;
            if (alp.includes(a)) {
                allAlp[alp.indexOf(a)].c++;
            }
        }
    });
    allAlp.forEach((a) => {
        const li = Object.assign(document.createElement("button"), {
            innerText: a.l,
            inert: a.c ? false : true,
            tabIndex: -1,
        });
        if (a.c) {
            li.dataset.target = `#v${a.l}`;
            li.addEventListener("click", (e) => {
                e.preventDefault();
                const target = e.target;
                if (!target.dataset.target)
                    return;
                const t = document.querySelector(target.dataset.target);
                if (t) {
                    t.focus();
                }
            });
        }
        vl.appendChild(li);
    });
    vendorFilters.appendChild(vl);
}
