(function(ContextHub) { "use strict";

    if (typeof ContextHub !== 'undefined') {

        ContextHub.console.log(ContextHub.Shared.timestamp(), '[loading] wcgcom-westcon.components.configuration.ad - ad.js');

        var ootbRandom = ContextHub.SegmentEngine.PageInteraction.StrategyManager.getStrategy('random');

        /**
         * Extend the OOTB random strategy, but simply remove the default candidate.
         *
         * @param {Array} candidates - list of candidates
         * @returns {Object|null} - returns random candidate or null
         */
        var randomCandidate = function (candidates) {

            if (candidates.length > 1) {
                // Filter out the default option if one is present
                candidates = candidates.filter(function (cand) {
                    return cand.name !== 'default';
                });
            }

            // pass the call to the OOTB random strategy handler.
            return ootbRandom.handler(candidates);
        };

        /* register strategy */
        ContextHub.SegmentEngine.PageInteraction.StrategyManager.register('random-without-default', 'Random (without default)', randomCandidate);
    }

})(window.ContextHub);
