// compiled from vendorsList.ts
if (document.querySelector(".search-ux")) {
    const url = new URL(window.location.href);
    const scrollTo = document.querySelector("[data-scroll-to-here]");
    const dropdownContainers = document.querySelectorAll(".search-ux-dropdown");
    if (scrollTo instanceof HTMLElement) {
        window.scrollTo({
            top: scrollTo.offsetTop,
            behavior: "smooth",
        });
    }
    function isDescendant(parents, target) {
        if (!parents || !(target instanceof Node)) return false;
        for (let i = 0; i < parents.length; i++) {
            if (parents[i].contains(target)) {
                return true;
            }
        }
        return false;
    }
    function closeAllDropdowns() {
        document.querySelectorAll(".search-ux-dropdown [aria-expanded]").forEach((t) => t.setAttribute("aria-expanded", "false"));
    }
    window.addEventListener("click", (event) => {
        const target = event.target;
        if (!target || !(target instanceof Element)) return;
        if (target && !isDescendant(dropdownContainers, target)) {
            closeAllDropdowns();
            return;
        }
        if (target.matches(".search-ux-dropdown button")) {
            event.stopPropagation();
            const initial = target.getAttribute("aria-expanded") === "true" ? "false" : "true";
            closeAllDropdowns();
            target.setAttribute("aria-expanded", initial);
        }
        if (target.matches(".search-ux-filter")) {
            event.preventDefault();
            const closestDropdown = target.closest(".search-ux-dropdown");
            performFilterRefresh(closestDropdown.dataset.filter, target.dataset.value);
        }
    });
    const searchInput = document.querySelector(".search-ux-search input");
    const searchButton = document.querySelector("search-ux-button");
    if (searchInput instanceof HTMLInputElement) {
        searchInput.addEventListener("keypress", function (event) {
            if (event.key === "Enter") {
                performFilterRefresh(searchInput.dataset.filter, searchInput.value);
            }
        });
    }
    if (searchButton instanceof HTMLElement && searchInput instanceof HTMLInputElement) {
        searchButton.addEventListener("click", () => {
            performFilterRefresh(searchInput.dataset.filter, searchInput.value);
        });
    }
    const clearButton = document.querySelector(".search-ux-clear");
    clearButton?.addEventListener("click", () => {
        window.location.href = url.origin + url.pathname;
    });
    const performFilterRefresh = (name, value) => {
        if (!name) return;
        url.searchParams.delete("page");
        if (!value) {
            url.searchParams.delete(name);
        } else {
            url.searchParams.set(name, value);
        }
        window.location.href = url.href;
    };
}
