// compiled from accordion.ts
document.querySelectorAll(".accordion-dt:not([data-active])").forEach((el) => {
    el.dataset.active = "true";
    el.addEventListener("click", (event) => {
        const target = event.target;
        const t = target?.matches("button") ? target : target?.closest("button");
        if (t instanceof HTMLElement) {
            t.setAttribute("aria-expanded", t.getAttribute("aria-expanded") === "false" ? "true" : "false");
            const closestItem = t.closest(".accordion-item");
            closestItem?.classList.toggle("is-open");
            const ariaCID = t.getAttribute("aria-controls");
            const ariaEl = ariaCID ? document.getElementById(ariaCID) : null;
            if (ariaEl instanceof HTMLElement) {
                ariaEl.setAttribute("aria-hidden", ariaEl.getAttribute("aria-hidden") === "false" ? "true" : "false");
                ariaEl.setAttribute("aria-expanded", ariaEl.getAttribute("aria-expanded") === "false" ? "true" : "false");
            }
        }
    }, globals.passiveSupported);
});
