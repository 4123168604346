// compiled from vendorShowcase.ts
document.querySelectorAll("body:not([data-edit-mode]) .vendor-showcase__list:not(.active)").forEach((showcase, indexShowcase) => {
    if (!(showcase instanceof HTMLElement))
        return;
    showcase.classList.add("active");
    globals.isVisibleObserver.observe(showcase);
    const containerID = "vdr-show-" + indexShowcase;
    showcase.dataset.id = containerID;
    const vendors = Array.from(showcase.querySelectorAll("li"));
    vendors.forEach((vendor, indexVendor) => {
        vendor.dataset.id = indexVendor.toString();
    });
    const shiftVendors = () => {
        const { visible } = showcase.dataset;
        if (visible === "1" && document.visibilityState === "visible" && !document.body.classList.contains("menu-open")) {
            let first = vendors.length - 1, last = 0;
            vendors.forEach((vendor) => {
                const id = vendor.dataset.id ? parseInt(vendor.dataset.id, 10) : 0;
                const rect = vendor.getBoundingClientRect();
                const isInViewport = rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth);
                if (isInViewport) {
                    if (id < first)
                        first = id;
                    if (id > last)
                        last = id;
                }
            });
            const target = last === vendors.length - 1 ? 0 : 2 * last - first > vendors.length - 1 ? vendors.length - 1 : 2 * last - first;
            const targetSlide = document.querySelector(`[data-id="${containerID}"] [data-id="${target}"]`);
            if (targetSlide) {
                targetSlide.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "nearest",
                });
            }
        }
    };
    let autoScroll = null;
    let permanent = false;
    function startTimer() {
        if (!autoScroll && !permanent) {
            autoScroll = window.setInterval(shiftVendors, 5000);
        }
    }
    function stopTimer(perm = false) {
        if (perm)
            return;
        if (autoScroll !== null)
            window.clearInterval(autoScroll);
        autoScroll = null;
        if (perm)
            permanent = true;
    }
    showcase.addEventListener("click", () => stopTimer(true), globals.passiveSupported);
    showcase.addEventListener("mouseenter", () => stopTimer(), globals.passiveSupported);
    showcase.addEventListener("mouseleave", () => startTimer(), globals.passiveSupported);
    window.addEventListener("focus", () => startTimer(), globals.passiveSupported);
    window.addEventListener("blur", () => stopTimer(), globals.passiveSupported);
    startTimer();
});
