function getCountryAndLanguage() {
    const validCountries = [
        "global",
        "mat", "ssa", "at", "be", "cz", "dk", "fi", "fr", "de", "gr", "it", "nl", "no", "pl", "pt", "es", "se", "ch", "uk", "me",
        "au", "cn", "hk", "id", "in", "jp", "kr", "my", "nz", "ph", "sg", "tw", "th", "vn",
    ];
    const validLangs = ["en", "fr", "de", "nl", "id", "th", "cht", "vn", "kr", "es", "pl", "cz", "pt", "it", "zh"];
    const urlObj = new URL(window.location.href);
    const pathParts = urlObj.pathname.toLowerCase().replace(".html", "").split("/").filter(Boolean);
    const country = pathParts[0] ?? "";
    const language = pathParts[1] ?? "";
    return {
        country: validCountries.includes(country) ? country : "",
        language: validLangs.includes(language) ? language : "",
    };
}
const globals = {
    country: "",
    langCode: "",
    langCodeFull: "",
    passiveSupported: false,
    customFetch: function (url, options = {}) {
        const defaultOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            timeout: 5000,
        };
        options = Object.assign(defaultOptions, options);
        const controller = new AbortController();
        const signal = controller.signal;
        options.signal = signal;
        if (options.timeout) {
            setTimeout(() => controller.abort(), options.timeout);
        }
        return fetch(url, options)
            .then((response) => {
            if (!response.ok) {
                const customMessages = {
                    400: "Bad request. The server could not understand the request.",
                    401: "Unauthorized. Please check your credentials.",
                    403: "Forbidden. You do not have permission to access this resource.",
                    404: "Resource not found.",
                    405: "Method not allowed. The method specified in the request is not allowed for this resource.",
                    408: "Request timeout. Please try again.",
                    500: "Internal server error. Something went wrong on the server.",
                    502: "Bad Gateway. The server received an invalid response from the upstream server.",
                    503: "Service Unavailable. The server is currently unable to handle the request.",
                    504: "Gateway Timeout. The server did not receive a timely response from the upstream server.",
                };
                const message = customMessages[response.status] || response.statusText;
                throw new Error(`Error in fetch: URL=${url}, Status=${response.status}, Message=${message}`);
            }
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                return response.json();
            }
            else {
                console.error(response);
                throw new Error("Invalid content type: Expected application/json");
            }
        })
            .catch((err) => {
            if (err.name === "AbortError") {
                console.error("Request was aborted", url, options, err);
            }
            else {
                console.error("Fetch error: ", err.message, " for ", url, options);
            }
            throw err;
        });
    },
    dynamicScriptLoad(scriptSrc, loadingStrategy, crossOrigin) {
        return new Promise((resolve, reject) => {
            try {
                if (document.querySelector(`script[src="${scriptSrc}"]`)) {
                    resolve();
                    return;
                }
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src = scriptSrc;
                if (loadingStrategy === "async")
                    script.async = true;
                if (loadingStrategy === "defer")
                    script.defer = true;
                if (crossOrigin === "anonymous")
                    script.crossOrigin = crossOrigin;
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Failed to load script: ${scriptSrc}`));
                document.head.appendChild(script);
            }
            catch (error) {
                reject(error);
            }
        });
    },
    getUrlParameters: (url = window.location.href) => {
        const urlObj = new URL(url);
        const params = urlObj.searchParams;
        const paramObj = {};
        for (const [key, value] of params.entries()) {
            paramObj[key] = value;
        }
        return paramObj;
    },
    dynamicScriptLoad(scriptSrc, loadingStrategy, crossOrigin) {
        return new Promise((resolve, reject) => {
            try {
                if (document.querySelector(`script[src="${scriptSrc}"]`)) {
                    resolve();
                    return;
                }
                const script = document.createElement("script");
                script.type = "text/javascript";
                script.src = scriptSrc;
                if (loadingStrategy === "async") script.async = true;
                if (loadingStrategy === "defer") script.defer = true;
                if (crossOrigin === "anonymous") script.crossOrigin = crossOrigin;
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Failed to load script: ${scriptSrc}`));
                document.head.appendChild(script);
            } catch (error) {
                reject(error);
            }
        });
    },
    createNewModal: (content, closeFunction) => {
        const dialog = document.createElement("dialog");
        const closeBtn = document.createElement("div");
        closeBtn.classList.add("close-dialog");
        dialog.append(closeBtn, content);
        document.body.appendChild(dialog);
        const enhancedCloseFunction = () => {
            closeFunction();
            dialog.close();
        };
        closeBtn.addEventListener("click", closeFunction, globals.passiveSupported);
        dialog.addEventListener("click", (event) => {
            if (event.target && event.target.nodeName === "DIALOG") {
                enhancedCloseFunction();
            }
        }, globals.passiveSupported);
        return dialog;
    },
    scrollToTop: (scrollAmt = 0) => {
        window.scrollTo({ top: scrollAmt, behavior: "smooth" });
    },
    debounce(func, wait) {
        let timeoutId = null;
        return (...args) => {
            const next = () => func(...args);
            if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
            timeoutId = setTimeout(next, wait);
        };
    },
    throttleTimer: false,
    throttle: (callback, time) => {
        if (globals.throttleTimer) {
            return;
        }
        globals.throttleTimer = true;
        setTimeout(() => {
            callback();
            globals.throttleTimer = false;
        }, time);
    },
    closePopup: {
        search: () => { },
        partnerCentral: () => { },
        language: () => { },
        closeAll: function () {
            this.search();
            this.partnerCentral();
            this.language();
        },
    },
    createAccessibleMenu: (container, elements, type = "inline") => {
        if (!container) {
            console.error("No container element provided for accessible menu:");
            console.trace();
            return;
        }
        if (elements.length === 0) {
            console.error("No elements provided for accessible menu:");
            console.trace();
            return;
        }
        const menuItems = Array.from(elements);
        const menuItemsLength = menuItems.length;
        const firstMenuItem = menuItems[0];
        const lastMenuItem = menuItems[menuItemsLength - 1];
        const [ArrowLeft, ArrowRight] = type === "inline" ? ["ArrowLeft", "ArrowRight"] : ["ArrowUp", "ArrowDown"];
        container.addEventListener("keydown", (event) => {
            const target = event.target;
            if (!menuItems.includes(target))
                return;
            const targetIndex = menuItems.indexOf(target);
            if (event.key === ArrowRight || event.key === ArrowLeft) {
                event.preventDefault();
                if (event.key === ArrowRight) {
                    const nextIndex = Math.min(targetIndex + 1, menuItemsLength);
                    menuItems[nextIndex]?.focus();
                }
                else if (event.key === ArrowLeft) {
                    const previousIndex = Math.max(targetIndex - 1, 0);
                    menuItems[previousIndex]?.focus();
                }
            }
            if (event.key === "End") {
                event.preventDefault();
                lastMenuItem?.focus();
            }
            if (event.key === "Home") {
                event.preventDefault();
                firstMenuItem?.focus();
            }
        });
    },
    introObserver: new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add("intro");
                globals.introObserver.unobserve(entry.target);
            }
        });
    }, {
        threshold: 0,
        rootMargin: "-50px 150px",
    }),
    imageVerifyLoad: new IntersectionObserver((images) => {
        images.forEach((image) => {
            if (image.isIntersecting && image.target instanceof HTMLImageElement) {
                const img = image.target;
                const isLoaded = img.complete || img.naturalHeight !== 0;
                img.dataset.ready = isLoaded.toString();
                if (!isLoaded) {
                    img.onload = () => {
                        img.dataset.ready = img.complete.toString();
                    };
                    img.onerror = () => {
                        img.dataset.error = "true";
                    };
                }
                globals.imageVerifyLoad.unobserve(img);
            }
        });
    }, {
        threshold: 0,
        rootMargin: "-50px 150px",
    }),
    isVisibleObserver: new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.target instanceof HTMLElement) {
                if (entry.isIntersecting) {
                    entry.target.dataset.visible = "1";
                }
                else {
                    entry.target.dataset.visible = "0";
                }
            }
        });
    }, {
        threshold: 0.5,
    }),
};
({ country: globals.country, language: globals.langCode } = getCountryAndLanguage());
globals.langCodeFull = document.documentElement?.lang ?? globals.langCode;
try {
    const options = Object.defineProperty({}, "passive", {
        get: function () {
            globals.passiveSupported = { passive: true };
            return false;
        },
    });
    window.addEventListener("test", null, options);
    window.removeEventListener("test", null, options);
}
catch (err) {
    globals.passiveSupported = false;
}
if (document.querySelector("[data-wistia-video]")) {
    globals
        .dynamicScriptLoad("https://fast.wistia.net/assets/external/E-v1.js", "async")
        .catch((error) => console.error("Wistia script failed to load", error));
}
