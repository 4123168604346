//compiled from animations.ts
if (document.querySelector(".editmode") === null && !document.body.classList.contains("animations")) {
    const pos = { x: 0, y: 0 };
    const saveCursorPosition = (x, y) => {
        pos.x = x / window.innerWidth;
        pos.y = y / window.innerHeight;
        document.documentElement.style.setProperty("--mouse-x", pos.x.toFixed(2));
        document.documentElement.style.setProperty("--mouse-y", pos.y.toFixed(2));
    };
    let rqAF;
    const updateCursorPosition = (e) => {
        saveCursorPosition(e.clientX, e.clientY);
        rqAF = null;
    };
    if ("requestIdleCallback" in window) {
        requestIdleCallback(() => {
            document.addEventListener(
                "mousemove",
                (e) => {
                    if (!rqAF) {
                        rqAF = window.requestAnimationFrame(() => {
                            updateCursorPosition(e);
                        });
                    }
                },
                globals.passiveSupported
            );
        });
    }
    document.querySelectorAll("[data-intro]").forEach((i) => {
        if (i) {
            globals.introObserver.observe(i);
        }
    });
    document.body.classList.add("animations");
}
