//compiled from partnerCentral.ts
"use strict";
class PartnerCentralMenu {
    constructor() {
        const pc = document.querySelector(".component-partner-central"),
            ico = document.getElementById("partnerCentralLink"),
            app = document.getElementById("partnerCentralDialog");
        this.boundOutsideClickListener = this.listenToOutsideClick.bind(this);
        if ([pc, app, ico].some((el) => el === null || !(el instanceof HTMLElement))) return;
        this.partnerCentral = pc;
        this.applications = app;
        this.pcIcon = ico;
        globals.closePopup.partnerCentral = this.toggleState;
        this.pcIcon?.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.toggleState("toggle");
        });
        document.getElementById("partnerCentralLogout")?.addEventListener("click", (event) => {
            event.preventDefault();
            const logoutUrl =
                event.target.getAttribute("href") || window.location.href.replace("partnercentral", "partner-central");
            document.cookie = `partnerCentralLogoutUrl=${encodeURIComponent(logoutUrl)}; path=/;`;
            location.href = "/system/sling/logout.html?tstamp=" + new Date().getTime();
        });
    }
    toggleState(state = "close") {
        if (state === "toggle") state = this.applications?.getAttribute("aria-hidden") == "false" ? "close" : "open";
        if (state === "open") {
            globals.closePopup.search();
            globals.closePopup.language();
            this.applications?.setAttribute("aria-hidden", "false");
            this.pcIcon?.setAttribute("aria-expanded", "true");
            document.addEventListener("click", this.boundOutsideClickListener);
        } else {
            this.applications?.setAttribute("aria-hidden", "true");
            this.pcIcon?.setAttribute("aria-expanded", "false");
            document.removeEventListener("click", this.boundOutsideClickListener);
        }
    }
    listenToOutsideClick(e) {
        const target = e.target;
        const outside = !this.partnerCentral.contains(target);
        if (outside) this.toggleState("close");
    }
}
if (document.querySelector('#partnerCentralLink[data-is-logged-in="true"]')) {
    new PartnerCentralMenu();
}
