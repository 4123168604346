// compiled from event-module.ts
if (document.querySelector(".component-event-module #app")) {
    initializeEventModule();
}
function initializeEventModule() {
    const eventContainer = document.querySelector(".component-event-module #app");
    if (document.body.dataset.editMode || !eventContainer) return;
    if (document.querySelector("script[src*=apps][src*=events]") instanceof HTMLScriptElement) {
        if (window.location.search.startsWith("?wcmmode=disabled")) {
            eventContainer.innerText = "You can only place the events module once on a single page";
        }
        return;
    }
    function isValidEventData(data) {
        if (typeof data !== "object" || data === null) return false;
        return "script" in data && typeof data.script === "string" && "css" in data && typeof data.css === "string";
    }
    globals
        .customFetch("/content/dam/apps/events/event-app.json")
        .then((data) => {
            if (isValidEventData(data)) {
                const script = document.createElement("script");
                script.async = true;
                script.type = "module";
                script.src = `/content/dam/apps/events/assets/index.${data.script}.js`;
                const css = document.createElement("link");
                css.rel = "stylesheet";
                css.href = `/content/dam/apps/events/assets/index.${data.css}.css`;
                document.body.append(script, css);
            }
        })
        .catch((error) => {
            console.error(`Error during fetch of log in status: ${error}`);
        });
}
