if (window.location.search.startsWith("?wcmmode=disabled")) {
    document.body.dataset.previewMode = "true";
}

//Adds the length of the headings for CSS adaptive font sizes
//want to move this into HTL but wasn't working
//!changed to server side
// document.querySelectorAll(":is(h1,h2)").forEach((el) => {
//     el.style.setProperty("--length", el.innerText.length.toString());
// });

//––––––––––––––––––––––––––––––––––––––––
//  Verify when images load or error
//––––––––––––––––––––––––––––––––––––––––
//add observer to check when images are in view
//adds data-ready="false" if not loaded yet (anticipate lazy loading) and data-ready="true" if/when loaded
//on errors, it also adds data-error="true" to the image
document.querySelectorAll("img").forEach((i) => {
    if (i) {
        globals.imageVerifyLoad.observe(i);
    }
});

//––––––––––––––––––––––––––––––––––––––––––––––––––––
//  Idea for transformation of variable within anchors
//––––––––––––––––––––––––––––––––––––––––––––––––––––
//looks for hyperlinks with a special code in them to indicate domains
//which is used for external marketo links, e.g
//* http://p.westconcomstor.com/XX_cis_somecampaign
//and will replace the XX with the relevant (current) country on the site
(() => {
    const linksToUpdate = document.querySelectorAll('a[href*="/XX"]');
    if (linksToUpdate.length && globals.country) {
        linksToUpdate.forEach((link) => (link.href = link.href.replace("/XX", "/" + globals.country)));
    }
})();
