// compiled from searchResults.ts
class SearchResultsClass {
    constructor() {
        this.searchField = document.getElementById("search-field");
        this.searchResultsContainer = document.getElementById("search-results-container");
        this.searchForm = document.getElementById("search-form");
        this.resetBtn = document.getElementById("reset-search");
        this.resultsQty = document.querySelector(".results-qty");
        this.paginationContainer = document.querySelector(".pagination-container nav");
        this.articlesContainer = document.querySelector(".articles-container");
        this.fetching = false;
        this.url = window.location.pathname.replace(".html", "") + "/_jcr_content.json";
        this.hashUpdating = false;
        this.query = {
            searchQuery: { query: "", currentPage: 0 },
            pagination: {
                currentPage: 0,
                totalPages: 1,
                totalResults: 0,
                pageSize: 10,
            },
            results: [],
        };
        const { first = "First", next = "Next", last = "Last", previous = "Previous" } = this.paginationContainer?.dataset || {};
        const noResults = this.articlesContainer?.dataset.noResults || "No results";
        this.translations = { first, next, last, previous, noResults };
        if (this.searchField && this.articlesContainer && this.searchResultsContainer && this.searchForm)
            this.init();
    }
    getHashParameters() {
        const currentParams = new URLSearchParams("?" + window.location.hash.split("#").pop());
        return { query: currentParams.get("query") || "", currentPage: parseInt(currentParams.get("currentPage") || "0") || 0 };
    }
    updateHashParameters() {
        window.location.hash = `#query=${this.query.searchQuery.query}&currentPage=${this.query.pagination.currentPage}`;
    }
    init() {
        this.query.searchQuery = this.getHashParameters();
        this.query.pagination.currentPage = this.query.searchQuery.currentPage;
        this.searchField.value = this.query.searchQuery.query;
        if (this.query.searchQuery.query)
            this.fetchSearchResults(this.query.pagination.currentPage);
        this.initEvents();
    }
    initEvents() {
        this.searchForm?.addEventListener("submit", (e) => {
            e.preventDefault();
            this.submitSearch();
        });
        this.resetBtn?.addEventListener("click", () => this.resetSearch());
        window.addEventListener("hashchange", () => {
            if (!this.hashUpdating) {
                const newParams = this.getHashParameters();
                this.query.searchQuery = newParams;
                this.query.pagination.currentPage = this.query.searchQuery.currentPage;
                this.resetAllResults();
                this.fetchSearchResults();
            }
        });
    }
    submitSearch() {
        if (this.searchField.value) {
            this.resetAllResults();
            this.query.searchQuery.query = this.searchField.value;
            this.updateHashParameters();
            this.fetchSearchResults();
        }
    }
    resetSearch() {
        this.hashUpdating = true;
        this.searchField.value = "";
        this.query.searchQuery.query = "";
        this.resetAllResults();
        this.updateHashParameters();
        this.hashUpdating = false;
    }
    changePage(newPage) {
        this.hashUpdating = true;
        this.query.pagination.currentPage = newPage;
        this.updateHashParameters();
        this.fetchSearchResults(newPage);
    }
    resetAllResults() {
        this.query.pagination.currentPage = 0;
        this.query.pagination.totalPages = 1;
        this.query.pagination.totalResults = 0;
        this.query.results = [];
        this.renderArticleResults();
    }
    isValidReceivedData(data) {
        if (typeof data !== "object" || data === null) {
            return false;
        }
        const pagination = data.pagination;
        if (!pagination ||
            typeof pagination.currentPage !== "number" ||
            typeof pagination.pageSize !== "number" ||
            typeof pagination.totalPages !== "number") {
            return false;
        }
        const results = data.results;
        if (!Array.isArray(results)) {
            return false;
        }
        for (const result of results) {
            if (typeof result !== "object" ||
                result === null ||
                typeof result.headline !== "string" ||
                typeof result.url !== "string" ||
                (result.description && typeof result.description !== "string")) {
                return false;
            }
        }
        return true;
    }
    fetchSearchResults(page = 1) {
        this.fetching = true;
        try {
            const params = new URLSearchParams({ query: this.query.searchQuery.query, currentPage: page.toString() });
            globals.customFetch(`${this.url}?${params}`).then((result) => {
                this.fetching = false;
                if (this.isValidReceivedData(result)) {
                    if (result.pagination.currentPage > 0 && result.pagination.totalResults && !result.results.length) {
                        this.changePage(0);
                        return;
                    }
                    this.query.results = result.results;
                    this.query.pagination.totalPages = result.pagination.totalPages;
                    this.query.pagination.totalResults = result.pagination.totalResults;
                    this.query.pagination.pageSize = result.pagination.pageSize;
                    this.renderArticleResults();
                    this.hashUpdating = false;
                }
                else {
                    throw new Error("Problem with the results");
                }
            });
        }
        catch (error) {
            console.error(error);
            throw error;
        }
    }
    createButton({ ariaLabel = "", classList = "", text, arrows = 0, onClick, }) {
        const li = document.createElement("li");
        const btn = document.createElement("button");
        btn.setAttribute("aria-label", ariaLabel);
        if (classList)
            li.classList.add(...classList.split(" "));
        btn.textContent = text;
        for (let j = 0; j < arrows; j++) {
            btn.append(Object.assign(document.createElement("div"), { classList: "arrow" }));
        }
        btn.addEventListener("click", onClick);
        li.append(btn);
        return li;
    }
    renderArticleResults() {
        const { currentPage, totalPages } = this.query.pagination;
        this.paginationContainer.innerHTML = "";
        if (totalPages > 1) {
            const nav = document.createElement("ul");
            nav.classList.add("pagination");
            const range = this.getPaginationRange();
            if (totalPages > 5 && currentPage > 0) {
                const first = this.createButton({
                    ariaLabel: this.translations.first,
                    classList: "prev",
                    text: "",
                    arrows: 2,
                    onClick: () => this.changePage(0),
                });
                nav.append(first);
            }
            const prev = this.createButton({
                ariaLabel: this.translations.previous,
                classList: `prev${currentPage === 0 ? " disabled" : ""}`,
                text: "",
                arrows: 1,
                onClick: () => this.changePage(currentPage - 1),
            });
            nav.append(prev);
            for (let i = range.start; i <= range.end; i++) {
                const cur = i === currentPage ? "current" : "";
                nav.append(this.createButton({ text: i.toString(), classList: cur, onClick: () => this.changePage(i) }));
            }
            const next = this.createButton({
                ariaLabel: this.translations.next,
                classList: `next${currentPage === totalPages ? " disabled" : ""}`,
                text: "",
                arrows: 1,
                onClick: () => this.changePage(currentPage + 1),
            });
            nav.append(next);
            if (totalPages > 5 && currentPage < totalPages) {
                const last = this.createButton({
                    ariaLabel: this.translations.last,
                    classList: "last",
                    text: "",
                    arrows: 2,
                    onClick: () => this.changePage(totalPages),
                });
                nav.append(last);
            }
            this.paginationContainer.append(nav);
        }
        const { itemsText = "Items", totalText = "total", toText = "to", ofText = "of" } = this.resultsQty.dataset;
        const { totalResults, pageSize } = this.query.pagination;
        if (!this.fetching && totalResults && totalResults > 11 && pageSize) {
            this.resultsQty.innerText = `${itemsText} ${currentPage * pageSize + 1} ${toText} ${Math.min(currentPage * pageSize + pageSize, totalResults)} ${ofText} ${Math.max(totalResults, this.query.results.length)} ${totalText}`;
        }
        else {
            this.resultsQty.innerText = "";
        }
        const hasValidResults = this.query.results.length > 0 && !this.fetching;
        this.articlesContainer.innerHTML = hasValidResults || !this.query.searchQuery.query ? "" : this.translations.noResults;
        this.query.results.forEach((result) => {
            const article = document.createElement("article");
            if (result.headline) {
                const heading = document.createElement("h2");
                if (result.url) {
                    const link = Object.assign(document.createElement("a"), { href: result.url, textContent: result.headline });
                    heading.append(link);
                }
                else {
                    heading.innerText = result.headline;
                }
                article.append(heading);
            }
            if (result.description) {
                const desc = Object.assign(document.createElement("p"), { innerText: result.description });
                article.append(desc);
            }
            this.articlesContainer.append(article);
            this.searchField.scrollIntoView({ behavior: "smooth", block: "center" });
        });
    }
    getPaginationRange() {
        const { currentPage, totalPages } = this.query.pagination;
        const start = totalPages <= 5 || currentPage <= 3 ? 1 : currentPage > totalPages - 2 ? totalPages - 4 : currentPage - 2;
        const end = totalPages <= 5 ? totalPages : currentPage <= 3 ? 5 : currentPage > totalPages - 2 ? totalPages : currentPage + 2;
        return { start, end };
    }
}
if (document.getElementById("search-results-container")) {
    new SearchResultsClass();
}
